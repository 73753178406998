$(function() {
  // ==================================================
  //  Header Menu Sp
  // ==================================================
  const js_buttonMenuClass = 'js_buttonMenu';
  const js_buttonMenuOpenClass = 'js_buttonMenu-open';
  const js_menuClass = 'js_menu';
  const js_menuOpenClass = 'js_menu-open';
  // const js_bodyClass = 'js_body';
  // const js_bodyFixedClass = 'js_body-fixed';
  $('.' + js_buttonMenuClass).on('click', function() {
    $(this).toggleClass(js_buttonMenuOpenClass);
    $('.' + js_menuClass).toggleClass(js_menuOpenClass);
    // $('.' + js_bodyClass).toggleClass(js_bodyFixedClass);
  });
});